
import { defineComponent, onMounted, ref, watch } from 'vue';
import { WhitelistType } from '@gemworks/gem-farm-ts';
import useCluster from '@/composables/cluster';
import useWallet from '@/composables/wallet';
import { initGemBank } from '@/common/gem-bank';
import { PublicKey } from '@solana/web3.js';

export default defineComponent({
  props: {
    bank: { type: String, required: true },
  },
  setup(props, ctx) {
    const action = ref<string>('add');
    const address = ref<string>();
    const type = ref<WhitelistType>(WhitelistType.Creator);

    const { getConnection } = useCluster();
    const { getWallet } = useWallet();

    let gb: any;

    const proofs = ref([]);

    onMounted(async () => {
      gb = await initGemBank(getConnection(), getWallet()!);
    });

    //todo doesn't work
    watch(
      () => props.bank,
      async () => {
        const r = await gb.fetchAllWhitelistProofPDAs(
          new PublicKey(props.bank)
        );
        console.log(`found a total of ${r.length} whitelist proofs`);
        proofs.value = r;
      }
    );

    const updateWhitelist = async () => {
      console.log(props.bank);

      if (action.value === 'add') {
        const { txSig } = await gb.addToWhitelistWallet(
          new PublicKey(props.bank),
          new PublicKey(address.value!),
          type.value
        );
        console.log('added', txSig);
        proofs.value = await gb.fetchAllWhitelistProofPDAs(
          new PublicKey(props.bank)
        );
      } else {
        const { txSig } = await gb.removeFromWhitelistWallet(
          new PublicKey(props.bank),
          new PublicKey(address.value!)
        );
        console.log('removed', txSig);
        proofs.value = await gb.fetchAllWhitelistProofPDAs(
          new PublicKey(props.bank)
        );
      }
    };

    const parseWhitelistType = (numType: number) => {
      switch (numType) {
        case 1:
          return 'Creator';
        case 2:
          return 'Mint';
        case 3:
          return 'Mint + Whitelist';
        default:
          return 'unknown';
      }
    };

    return {
      WhitelistType,
      action,
      address,
      type,
      proofs,
      updateWhitelist,
      parseWhitelistType,
    };
  },
});
