
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    bank: Object,
    bankAcc: Object,
  },
  setup() {
    return {};
  },
});
