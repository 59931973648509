
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    vaultAcc: Object,
  },
  setup() {
    return {};
  },
});
