import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfigPane = _resolveComponent("ConfigPane")!
  const _component_BankDetails = _resolveComponent("BankDetails")!
  const _component_TheWhitelist = _resolveComponent("TheWhitelist")!
  const _component_ManageVaults = _resolveComponent("ManageVaults")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ConfigPane),
    (!_ctx.wallet)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Pls connect (burner) wallet"))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.bank)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.bankAcc)
                  ? (_openBlock(), _createBlock(_component_BankDetails, {
                      key: _ctx.bankAcc,
                      bank: _ctx.bank,
                      bankAcc: _ctx.bankAcc,
                      class: "mb-10"
                    }, null, 8, ["bank", "bankAcc"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_TheWhitelist, {
                  bank: _ctx.bank ? _ctx.bank.toBase58() : undefined,
                  class: "mb-10"
                }, null, 8, ["bank"]),
                _createVNode(_component_ManageVaults, {
                  bank: _ctx.bank,
                  key: _ctx.bank
                }, null, 8, ["bank"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("button", {
                  class: "nes-btn is-primary",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startBank && _ctx.startBank(...args)))
                }, " Start a new bank ")
              ]))
        ]))
  ], 64))
}