import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/play.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", null, [
    _createElementVNode("img", {
      src: _imports_0,
      alt: "right",
      width: 60,
      height: 60,
      class: _normalizeClass({ left: _ctx.left })
    }, null, 2)
  ]))
}